<template>

  <div>

    <div class="top_style">
      <img alt="Vue logo" src="../../../public/logo.png" style="width: 42px; height: 42px;margin-left: 15px">
      <span class="span_center">Lucky Maker</span>

      <div style="width: 100%;">
        <div style="float: right;margin-right: 15px;">
      
          <span class="tab_type" @click="jumpHome" style="color: #FFCA12">Home</span>
          <span class="tab_type" @click="jumpAboutUs" style="margin-left: 30px">About Us</span>
          <span class="tab_type" @click="jumpContactUs" style="margin-left: 30px">Contact Us</span>

        </div>
      </div>
    </div>


    <div class="wrap-content" style="overflow: hidden">


      <div style="width: 100%;height: 320px; background-color: #8B60F0"></div>
      <div class="triangle" :style="{borderRight: screenWidth * 2 + 'px' + ' solid transparent'}"></div>

      <div style="display: flex">
        <img src="../../assets/img/lotto.png">
        <div style="margin: 40px 40px 0px 40px;width: 100%">
          <p style="color: #602CCD;font-weight: bold;font-size: 36px">Get to know us</p>
          <div style="background-color: #602CCD;height: 4px;margin-top: 30px"></div>
          <p style="margin-top: 30px">Our mission</p>
          <p style="margin-top: 10px">Create a game where users</p>
          <p style="margin-top: 10px">have the opportunity to win real</p>
          <p style="margin-top: 10px">prizes every day</p>
          <p style="margin-top: 10px">Without the risk of losing</p>
          <p style="margin-top: 10px">everything</p>
        </div>
      </div>

      <div class="bottom_triangle" :style="{borderLeft: screenWidth * 2 + 'px' + ' solid transparent'}"></div>
      <div style="width: 100%;height: 160px; background-color: #8B60F0"></div>

      <div class="phone_style" style="width: 100%;">
        <img style="float: right" src="../../assets/img/phone.png">
        <p class="sp_type">Play free games win real money</p>
      </div>
      <div style="position: absolute;top: 320px;left: 25px;display: flex">
        <el-button class="btn_bg_div" @click="jumpLink">
          <div style="display: flex">
            <img src="../../assets/img/google_play.png" style="width: 25px;height: 25px">
            <div style="margin-left: 10px">
              <p style="font-size: 10px;text-align: left">GET IN ON</p>
              <p style="font-size: 16px;">Google Play</p>
            </div>
          </div>
        </el-button>
        <!-- <el-button class="btn_bg_div" style="font-size: 16px;margin-left: 30px">Download APK</el-button> -->
      </div>

      <div style="position: absolute;bottom: 20px;left: 25px;display: flex;width: 100%">
        <div style="width: 30%">
          <p style="color: #602CCD;font-weight: bold;font-size: 36px">Game to win</p>
          <div style="background-color: #602CCD;height: 4px;margin-top: 30px"></div>
          <p style="margin-top: 30px">Game fairness is our principle. </p>
          <p style="margin-top: 10px">We use the revenue provided</p>
          <p style="margin-top: 10px">by the advertiser as the</p>
          <p style="margin-top: 10px">source of the player’s</p>
          <p style="margin-top: 10px">winnings. We will continue to</p>
          <p style="margin-top: 10px">create opportunities to win.</p>
          <p style="margin-top: 10px">Come on, the next winner is you!</p>
        </div>

        <div style="justify-content: center;width: 100%;margin-left: 50px">
          <div style="float: right;display: flex;margin-right: 50px">

            <div style="position: relative;">
              <img class="img_w_h" src="../../assets/img/win_bg.png">
              <div style="position: absolute;top: 25%;left:28%;">
                <div style="text-align: center">
                  <img src="../../assets/img/icon.png">
                  <el-button class="left_btn_bg" @click="jumpLink">
                    <div style="display: flex;">
                      <img src="../../assets/img/google_play.png" style="width: 25px;height: 25px">
                      <div style="margin-left: 10px">
                        <p style="font-size: 10px;text-align: left">GET IN ON</p>
                        <p style="font-size: 16px;">Google Play</p>
                      </div>
                    </div>
                  </el-button>
                </div>
              </div>
            </div>

            <div style="position: relative;">
              <img class="img_w_h" src="../../assets/img/unknown.png">
              <div style="position: absolute;top: 40%;width:80%;left: 8px">
                <el-button class="right_btn_bg" style="font-size: 16px;margin-left: 30px;text-align: center">Coming
                  soon!
                </el-button>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

    <div style="padding-bottom: 30px;padding-top:30px;background: #2F1A5E;text-align: center">
      <!-- <span class="tab_type" @click="jumpPrivacyPolicy">privacy policy</span>
      <span class="tab_type" @click="jumpUsePolicy" style="margin-left: 60px">Terms of use</span>

      <div class="flex_center" style="margin-top: 30px">
        <img alt="Vue logo" src="../../assets/img/instagram.png">
        <img alt="Vue logo" src="../../assets/img/in.png" style="margin-left: 40px">
        <img alt="Vue logo" src="../../assets/img/facebook.png" style="margin-left: 40px">
        <img alt="Vue logo" src="../../assets/img/bird.png" style="margin-left: 40px">
        <img alt="Vue logo" src="../../assets/img/youtobe.png" style="margin-left: 40px">
      </div> -->

      <div class="flex_center" style="margin-left: 80px;margin-right: 80px;">
            <div style="width:50%;text-align: left;">
                <p class="tab_type" style="color: #FFCA12">Company:</p>
                <p class="tab_type" >FOX INFORMATION PTE. LTD. </p>

                <p class="tab_type" style="color: #FFCA12; margin-top:30px;">LOCATION:</p>
                <p class="tab_type" >160 ROBINSON ROAD #20-03 SINGAPORE (068914)</p>

                <p class="tab_type" style="color: #FFCA12; margin-top:30px;">EMAIL:</p>
                <p class="tab_type" >support@luckymaker.com</p>
            </div>

            <div style="width:50%;text-align: left;">
                <p class="tab_type" >Company:</p>
                <p class="tab_type" @click="jumpAboutUs" style="color:#FFCA12;margin-top:10px;">>> About Us</p>
                <p class="tab_type" @click="jumpContactUs" style="color: #FFCA12;margin-top:10px;">>> Contact Us</p>
                <p class="tab_type" @click="jumpPrivacyPolicy" style="color: #FFCA12;margin-top:10px;">>> Privacy Policy</p>
                <p class="tab_type" @click="jumpUsePolicy" style="color: #FFCA12;margin-top:10px;">>> Terms & Condition</p>
            </div>
        </div>
        <div class="tab_type" style="color: #FFCA12;margin-top:30px;">© 2023. All rights reserved by FOX INFORMATION PTE. LTD.</div>

    </div>

  </div>

</template>

<script>
export default {
  name: "index",

  data() {
    return {
      screenWidth: document.body.clientWidth,     // 屏幕宽
      screeHeight: document.body.clientHeight,    // 屏幕高

    }
  },
  created() {
    this.calculateSize();
    window.addEventListener('resize', this.calculateSize);
  },
  mounted() {
    let self = this;
    setTimeout(function () {
      self.calculateSize();
    }, 500);
  },
  methods: {
    calculateSize() {

    },
    jumpLink(){
      location.href='https://play.google.com/store/apps/details?id=com.mz.luckymaker&hl=en_US&gl=US';
    },
    /**
     * 打开首页
     */
    jumpHome() {
      this.$router.push({
        path: '/',
      });
    },
    /**
     * 打开关于我们界面
     */
    jumpAboutUs() {
      this.$router.push({
        path: '/aboutUs',
      });
    },
    /**
     * 打开联系我们界面
     */
    jumpContactUs() {
      this.$router.push({
        path: '/contactUs',
      });
    },
    /**
     * 打开隐私协议
     */
    jumpPrivacyPolicy() {
      this.$router.push({
        path: '/PrivacyAgreement',
      });
    },
    /**
     * 打开用户协议
     */
    jumpUsePolicy() {
      this.$router.push({
        path: '/UserAgreement',
      });
    },
  },
}
</script>

<style scoped>

.sp_type {
  color: white;
  font-family: Arial;
  font-size: 36px;
  color: white;
  font-weight: bold;
  margin: 130px 0px 0px 25px;
}

.wrap-content {
  overflow: auto;
  position: relative;
  font-size: 20px;
  background-color: white;
}

.phone_style {
  position: absolute;
  top: 100px;
  /*实现垂直居中*/
  /*align-items: center;*/
  /*!*实现水平居中*!*/
  justify-content: center;

  /*display: table-cell;*/
  /*vertical-align: middle;*/
}

.btn_bg_div {
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid white;
  outline: none;
  color: #ffffff;
  background: transparent;
  box-sizing: border-box;
  font-size: 0.93em;
  padding: 10px;

  /*flex 布局*/
  display: flex;
  /*实现垂直居中*/
  align-items: center;
}

.left_btn_bg {
  cursor: pointer;
  border-radius: 10px;
  border: 0px solid transparent;
  outline: none;
  color: #ffffff;
  background: linear-gradient(140deg, #F7D34B, #6131C2, #623BEF);
  box-sizing: border-box;
  font-size: 0.93em;
  width: 180px;
  height: 60px;

  /*flex 布局*/
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  margin-top: 10px;
}

.right_btn_bg {
  cursor: pointer;
  outline: none;
  color: #ffffff;
  box-sizing: border-box;
  font-size: 0.93em;
  width: 100%;
  height: 40px;
  border-width: 0px;
  background: linear-gradient(78deg, #6131C4, #623BF0);
  border-radius: 25px;

  /*!*flex 布局*!*/
  /*display: flex;*/
  /*!*实现垂直居中*!*/
  /*align-items: center;*/
}

.img_w_h {
  width: 372px;
  height: 430px;
}

.center_div {
  width: 372px;
  height: 430px;
  display: table;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: center;
}

/*.triangle {*/
/*  width: 100%;*/
/*  height: 0;*/
/*  border-top: 100px solid #8B60F0;*/
/*  border-left: 100px solid transparent;*/
/*}*/

.triangle {
  width: 100%;
  height: 0;
  border-top: 400px solid #8B60F0;
  /*border-right: 1800px solid transparent;*/
}

.bottom_triangle {
  width: 100%;
  height: 0;
  border-bottom: 360px solid #8B60F0;
  /*border-left: 1800px solid transparent;*/
}

.top_style {
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  padding: 10px;
  background: linear-gradient(78deg, #6131C4, #623BF0);
}

.tab_type {
  font-size: 16px;
  color: white;
  font-family: Arial;
}

.span_center {
  width: 100%;
  font-weight: bold;
  font-size: 26px;
  color: white;
  margin-left: 10px;
}

.flex_center {
  /*flex 布局*/
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: center;

  text-align: justify;
  /*width:200px;*/
  /*height:200px;*/
  /*background: #000;*/
  margin: 0 auto;
  color: #fff;
}


</style>